const formFile = (() => {

  const init = () => {

    // console.log('form-file.js init')
    _setupListeners()
  }

  const _setupListeners = () => {

    document.addEventListener('change', (event) => {
      if (event.target.dataset.fileInput === undefined) return

      const that = event.target
      const root =  that.closest('[data-file]')
      const attached = root.querySelector('[data-file-attached]')

      attached.textContent = that.files[0].name
      root.classList.add('_is-attached')
    })

    // console.log('form-file.js setup listeners')
  }

  return {
    init,
  }
})()

formFile.init()

