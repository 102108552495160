const magnet = (() => {
  let methods = {
    magnet: (event, element, movement) => {
      const target = element
      const boundingRect = target.getBoundingClientRect()
      const relX = event.pageX - boundingRect.left
      const relY = event.pageY - boundingRect.top

      if (!movement) {
        movement = 10
      }

      gsap.to(target, {
        x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
        y: ((relY - boundingRect.height / 2) / boundingRect.height) * movement,
        z: 0,
        ease: 'linear',
        duration: 0.2,
        // force3D: true,
      })
    },
    magnetLeave: (element) => {
      gsap.to(element, {
        ease: 'elastic.out(0.8, 0.4)',
        duration: 1,
        x: 0,
        y: 0,
        z: 0,
        // force3D: true,
      })
    },
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    _setupListeners()
  }

  const _setupListeners = () => {
    let magnetAll = document.querySelectorAll('[data-magnet]')

    for (const magnetAllElement of magnetAll) {
      magnetAllElement.addEventListener('mousemove', (event) =>
        methods.magnet(event, magnetAllElement)
      )

      magnetAllElement.addEventListener('mouseleave', () =>
        methods.magnetLeave(magnetAllElement)
      )
    }
  }

  return {
    init,
  }
})()

magnet.init()
