const footer = (() => {
  const footer = document.querySelectorAll('.footer')

  // Инициализация
  let init = () => {
    // const mq = window.matchMedia('(min-width: 1024px)')
    // if (!mq.matches) return

    if (!footer.length) return

    const tl = gsap.timeline({
      ease: 'Expo',
      scrollTrigger: {
        trigger: '.footer',
      },
    })

    tl.set(
      '.footer__link-logo, .footer__info > *, .footer__bottom, .footer__dealer',
      {
        willChange: 'transform, opacity',
      }
    )

    tl.from('.footer__link-logo', {
      autoAlpha: 0,
      y: 64,
      scale: 0.9,
      duration: 1.2,
      delay: 0.3,
    })
      .from(
        '.footer__info > *, .footer__dealer',
        {
          y: 32,
          autoAlpha: 0,
          stagger: 0.24,
          duration: 1.2,
        },
        '-=0.6'
      )
      .from(
        '.footer__main',
        {
          autoAlpha: 0,
          duration: 1.2,
        },
        '-=0.6'
      )
      .from(
        '.footer__bottom',
        {
          autoAlpha: 0,
          duration: 1.2,
        },
        '-=0.6'
      )

    tl.set(
      '.footer__link-logo, .footer__info > *, .footer__bottom, .footer__dealer',
      {
        willChange: 'auto',
      }
    )

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {}

  // Доступные методы
  return {
    init,
  }
})()

window.addEventListener('load', () => {
  footer.init()
})
