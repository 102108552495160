(function (factory) {
	if (typeof define === "function" && define.amd) {
		define(["jquery", "../../../node_modules/jquery-validation/dist/jquery.validate"], factory);
	} else if (typeof module === "object" && module.exports) {
		module.exports = factory(require("jquery"));
	} else {
		factory(jQuery);
	}
}(function ($) {

	switch ($('html').attr('lang')) {
		case 'ru':
			$.extend($.validator.messages, {
				required: "Это поле необходимо заполнить",
				remote: "Пожалуйста, введите правильное значение",
				email: "Пожалуйста, введите корректный адрес электронной почты",
				url: "Пожалуйста, введите корректный URL",
				date: "Пожалуйста, введите корректную дату",
				dateISO: "Пожалуйста, введите корректную дату в формате ISO",
				number: "Пожалуйста, введите число",
				digits: "Пожалуйста, вводите только цифры",
				creditcard: "Пожалуйста, введите правильный номер кредитной карты",
				equalTo: "Пожалуйста, введите такое же значение ещё раз",
				extension: "Пожалуйста, выберите файл с правильным расширением",
				maxlength: $.validator.format("Пожалуйста, введите не больше {0} символов"),
				minlength: $.validator.format("Пожалуйста, введите не меньше {0} символов"),
				rangelength: $.validator.format("Пожалуйста, введите значение длиной от {0} до {1} символов"),
				range: $.validator.format("Пожалуйста, введите число от {0} до {1}"),
				max: $.validator.format("Пожалуйста, введите число, меньшее или равное {0}"),
				min: $.validator.format("Пожалуйста, введите число, большее или равное {0}"),
				dateGreaterThan: $.validator.format("Пожалуйста, укажите дату после {0}")
			});
			break;
		default:
			$.extend($.validator.messages, {
				required: "This field is required",
				remote: "Please fix this field",
				email: "Please enter a valid email address",
				url: "Please enter a valid URL",
				date: "Please enter a valid date",
				dateISO: "Please enter a valid date (ISO)",
				number: "Please enter a valid number",
				digits: "Please enter only digits",
				equalTo: "Please enter the same value again",
				maxlength: $.validator.format( "Please enter no more than {0} characters" ),
				minlength: $.validator.format( "Please enter at least {0} characters" ),
				rangelength: $.validator.format( "Please enter a value between {0} and {1} characters long" ),
				range: $.validator.format( "Please enter a value between {0} and {1}" ),
				max: $.validator.format( "Please enter a value less than or equal to {0}" ),
				min: $.validator.format( "Please enter a value greater than or equal to {0}" ),
				step: $.validator.format( "Please enter a multiple of {0}" ),
				dateGreaterThan: $.validator.format("Please enter a date after {0}")
			});
	}

	return $;
}));
