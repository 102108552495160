const loadScreen = (() => {
	let state = {
		path: null,
	}

	const hide = () => {
		const introTitle = document.querySelector('.intro-section__title')

		if (introTitle) {
			const boxTitle = introTitle.getBoundingClientRect()
			const loadText = document.querySelector('.load-screen__text')
			const boxText = loadText.getBoundingClientRect()
			state.path = -(boxText.y - boxTitle.y)
		} else {
			state.path = 0
		}

		const tlHide = gsap.timeline()

		tlHide.set('.load-screen__back, .load-screen__text, .load-screen', {
			willChange: 'transform, opacity',
		})

		tlHide
			.to('.load-screen__back', {
				scale: 1.4,
				yPercent: -10,
				autoAlpha: 0,
				delay: 2.4,
			})
			.to(
				'.load-screen__text',
				{
					y: state.path,
					ease: 'Sine.easeOut',
				},
				'-=0.5'
			)
			.to('.load-screen', {
				autoAlpha: 0,
			})

		tlHide.set('.load-screen__back, .load-screen__text, .load-screen', {
			willChange: 'auto',
		})
	}

	const init = () => {
		if (!document.querySelector('[data-load-screen]')) return
		if (document.body.classList.contains('page_detail')) return

		_setupListeners()
	}

	const _setupListeners = () => {
		window.addEventListener('load', () => {
			loadScreen.hide()
		})
	}

	return {
		init,
		hide,
	}
})()

loadScreen.init()
