const benefitsSection = (() => {
  // Инициализация
  let init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    const benefitsSection = document.querySelectorAll('.benefits-section')
    if (!benefitsSection.length) return

    const changed = gsap.utils.toArray(
      '.benefits-section__title, .benefits-section__amount, .benefits-section__text'
    )

    const tl = gsap.timeline({
      ease: 'Back.inOut',
      scrollTrigger: {
        trigger: '.benefits-section__head',
        // start: 'top 80%',
        // scrub: true,
        // end: 'center center',
        // once: true,
      },
    })

    tl.set(changed, { willChange: 'transform, opacity' })
      .from(changed, {
        autoAlpha: 0,
        y: 48,
        duration: 0.6,
        stagger: 0.3,
        delay: 0.2,
      })
      .set(changed, { willChange: 'auto' })

    const benefitsItems = document.querySelectorAll('.benefits-section__item')
    let changedElements = document.querySelectorAll(
      '.benefits-item, .benefits-item__outer-note, .benefits-item__circle'
    )

    ScrollTrigger.create({
      trigger: '.benefits-section__body',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const changedElement of changedElements) {
            changedElement.classList.add('will-change')
          }
        } else {
          for (const changedElement of changedElements) {
            changedElement.classList.remove('will-change')
          }
        }
      },
    })

    for (let i = 0; i < benefitsItems.length; i++) {
      const benefitsItemsElement = benefitsItems[i]
      const benefitsItem = benefitsItemsElement.querySelector('.benefits-item')
      const benefitsNote = benefitsItemsElement.querySelector(
        '.benefits-item__outer-note'
      )
      const benefitsCircle = benefitsItemsElement.querySelector(
        '.benefits-item__circle'
      )

      const tl = gsap.timeline({
        ease: 'Back.inOut',
        delay: i * 0.4,
        scrollTrigger: {
          trigger: '.benefits-section__body',
          start: 'top 80%',
        },
      })

      tl.from(benefitsItem, {
        autoAlpha: 0,
        scale: 1.3,
        duration: 0.6,
      })
        .from(
          benefitsItem,
          {
            y: 48,
            duration: 1.4,
            ease: 'Sine.easeOut',
          },
          '-=0.4'
        )
        .from(
          benefitsCircle,
          {
            autoAlpha: 0,
            duration: 0.4,
            ease: 'Sine.easeOut',
          },
          '<'
        )
        .from(
          benefitsCircle,
          {
            rotation: -60,
            duration: 2.4,
            delay: 0.04,
            ease: 'Expo.easeInOut',
          },
          '<'
        )
        .from(benefitsNote, {
          autoAlpha: 0,
        })
    }

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {}

  return {
    init,
  }
})()

const benefitsSectionMobile = (() => {
  const animateHead = () => {
    const willChangeHeadElements = document.querySelectorAll(
      '.benefits-section__title, .benefits-section__amount, .benefits-section__text'
    )

    ScrollTrigger.create({
      trigger: '.benefits-section__head',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeHeadElement of willChangeHeadElements) {
            willChangeHeadElement.classList.add('will-change')
          }
        }
      },
    })

    const elements = gsap.utils.toArray(
      '.benefits-section__title, .benefits-section__amount, .benefits-section__text'
    )

    gsap.from(elements, {
      autoAlpha: 0,
      y: 16,
      duration: 0.6,
      stagger: 0.3,
      delay: 0.2,
      ease: 'Sine.easeOut',
      scrollTrigger: {
        trigger: '.benefits-section__head',
      },
      onComplete: () => {
        for (const willChangeHeadElement of willChangeHeadElements) {
          willChangeHeadElement.classList.remove('will-change')
        }
      },
    })
  }

  const animateBody = () => {
    const willChangeElements = document.querySelectorAll('.benefits-item')

    ScrollTrigger.create({
      trigger: '.benefits-section__body',
      // once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        }
      },
    })

    const benefitsItems = document.querySelectorAll('.benefits-section__item')

    for (let i = 0; i < benefitsItems.length; i++) {
      const benefitsItemsElement = benefitsItems[i]
      const benefitsItem = benefitsItemsElement.querySelector('.benefits-item')

      const tl = gsap.timeline({
        ease: 'Sine.easeOut',
        scrollTrigger: {
          trigger: benefitsItemsElement,
          start: 'top 90%',
          toggleActions: 'play pause resume reverse',
        },
      })

      tl.from(benefitsItem, {
        opacity: 0.0001,
        scale: 1.3,
        delay: 0.1,
      }).from(
        benefitsItem,
        {
          y: 24,
          delay: 0.3,
        },
        '<'
      )
    }
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (mq.matches) return

    animateHead()
    animateBody()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  benefitsSection.init()
  benefitsSectionMobile.init()
})
