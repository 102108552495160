import Inputmask from 'inputmask/lib/inputmask'

const formInput = (() => {
	const state = {
		fieldPhoneAll: null,
	}

	// Инициализация
	let init = () => {
		state.fieldPhoneAll = document.querySelectorAll('[data-field-phone]')

		for (const fieldPhoneElement of state.fieldPhoneAll) {
			// console.log(fieldPhoneElement)

			const im = new Inputmask('+7 (999) 999-9999', {
				showMaskOnHover: false,
				clearIncomplete: true,
			})
			im.mask(fieldPhoneElement.querySelector('.form-input__field'))
		}

		_setupListeners()
	}

	// Навешиванеи событий
	let _setupListeners = () => {
		document.addEventListener('focusin', (event) => {
			const that = event.target

			if (that.classList.contains('form-input__field')) {
				const formInput = that.closest('.form-input')

				formInput.classList.add('_is-focus')
			}
		})

		document.addEventListener('focusout', (event) => {
			const that = event.target

			if (that.classList.contains('form-input__field')) {
				const formInput = that.closest('.form-input')
				const value = that.value

				formInput.classList.remove('_is-focus')

				if (!value) {
					formInput.classList.remove('_is-filled')
				} else {
					formInput.classList.add('_is-filled')
				}
			}
		})

		document.addEventListener('change', (event) => {
			const that = event.target

			if (!that.classList.contains('form-input__field')) return
			if (!that.value) return

			const formInput = that.closest('.form-input')
			formInput.classList.add('_is-filled')
		})
	}

	return {
		init,
	}
})()

window.addEventListener('load', () => {
	formInput.init()
})
