const feedbackMenu = (() => {
  // const mq = window.matchMedia('(min-width: 1024px)')

  // Селектор
  const feedbackMenu = document.querySelector('.feedback-menu')

  // DATA
  let data = {
    buttonMenuOpenAll: document.querySelectorAll('[data-feedback-open]'),
    buttonMenuClose: document.querySelector('[data-feedback-close]'),
  }

  // // METHODS
  let methods = {
    openMenu: () => {
      if (body.classList.contains('menu_open')) {
        window.closeMenu()
        setTimeout(() => {
          body.classList.add('feedback_open')
        }, 1000)
      } else {
        body.classList.add('feedback_open')
      }

      const tl = gsap.timeline()

      tl.from('.feedback-menu__head', {
        opacity: 0,
        duration: 0.6,
        delay: 0.5,
      }).from('.feedback-menu__field-form, .feedback-menu__action', {
        yPercent: -24,
        opacity: 0,
        duration: 0.3,
        stagger: 0.08,
        force3D: true,
      }, '-=0.4')
    },
    closeMenu: () => {
      body.classList.add('feedback_closing')

      setTimeout(() => {
        body.classList.remove('feedback_closing', 'feedback_open')
      }, 1000)
    }
  }

  // Инициализация
  let init = () => {
    if (!feedbackMenu) return

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {
    // data.buttonMenuOpenAll.addEventListener('click', methods.openMenu)

    for (let index = 0; index < data.buttonMenuOpenAll.length; index++) {
      const element = data.buttonMenuOpenAll[index]
      element.addEventListener('click', methods.openMenu)
    }

    data.buttonMenuClose.addEventListener('click', methods.closeMenu)

    feedbackMenu.addEventListener('click', (event) => {
      if (event.target.classList.contains('feedback-menu__backdrop')) {
        methods.closeMenu()
      }
    })
  }

  return {
    init
  }
})()

window.addEventListener('load', () => {
  feedbackMenu.init()
})
