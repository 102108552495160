import lazySizes from 'lazysizes'
// import a plugin
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks.js'
// import { ScrollTrigger } from 'gsap/ScrollTrigger';

lazySizes.cfg.loadMode = 2
lazySizes.cfg.preloadAfterLoad = true
lazySizes.cfg.lazyClass = 'lazy'
lazySizes.cfg.preloadClass = '_preload'
lazySizes.cfg.loadingClass = '_is-loading'
lazySizes.cfg.loadedClass = '_is-loaded'
lazySizes.cfg.customMedia = {
  tablet: '(min-width: 640px)',
  laptop: '(min-width: 1024px)',
  desktop: '(min-width: 1280px)',
}

/**
 * Example
<picture>
  <source data-srcset="" media="laptop" />
  <img
    class="lazy"
    data-src=""
    data-sizes="auto"
    alt=""
  />
</picture>
 */
