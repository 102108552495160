import anime from 'animejs/lib/anime.es'

const extremeSection = (() => {
  const animate = () => {
    gsap.from(
      '.extreme-section__back span, .extreme-section__title, .extreme-section__subtitle',
      {
        scrollTrigger: {
          trigger: '.extreme-section__back',
          // start: 'bottom bottom'
        },
        autoAlpha: 0,
        scale: 1.6,
        duration: 1.2,
        stagger: 0.2,
        ease: 'Expo.In',
      }
    )

    gsap.fromTo(
      '.extreme-section__head',
      {
        scrollTrigger: {
          trigger: '.extreme-section__head',
          scrub: true,
        },
        y: 160,
        ease: 'none',
      },
      {
        scrollTrigger: {
          trigger: '.extreme-section__head',
          scrub: true,
        },
        y: -160,
        ease: 'none',
      }
    )
  }

  const animatePaths = () => {
    const tl = anime.timeline({
      easing: 'easeInSine',
      duration: 2000,
    })

    tl.add({
      targets: '.extreme-section__line-svg-in',
      strokeDashoffset: [anime.setDashoffset, 0],
    })
      .add({
        targets: '.extreme-section__back-svg-out',
        strokeDashoffset: [anime.setDashoffset, 0],
      })
      .add(
        {
          targets: '.extreme-section__line-svg-out',
          strokeDashoffset: [anime.setDashoffset, 0],
        },
        '-=1300'
      )

    tl.pause()
    tl.seek(0)

    ScrollTrigger.create({
      trigger: '.extreme-section__in-picture',
      end: 'bottom center',
      scrub: 1,
      onUpdate: (self) => {
        tl.seek(tl.duration * self.progress.toFixed(3))
      },
    })
  }

  const animateExtremePicture = () => {
    const tlExtreme = gsap.timeline({
      scrollTrigger: {
        trigger: '.extreme-section__in-picture',
        start: 'bottom bottom',
      },
    })

    tlExtreme
      .from('[data-extreme-picture]', {
        autoAlpha: 0,
        duration: 0.8,
        y: 24,
        ease: 'Sine.easeOut',
      })
      .from('[data-extreme-info]', {
        autoAlpha: 0,
        x: -16,
        duration: 1.6,
        ease: 'Expo.easeOut',
      })
  }

  const init = () => {
    const element = document.querySelectorAll('.extreme-section')
    if (!element.length) return

    animatePaths()

    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    animate()
    animateExtremePicture()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

const extremeSectionMobile = (() => {
  const animateHead = () => {
    const willChangeElements = document.querySelectorAll(
      '.extreme-section__back span, .extreme-section__title, .extreme-section__subtitle'
    )

    ScrollTrigger.create({
      trigger: '.extreme-section__back',
      // once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        } else {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.remove('will-change')
          }
        }
      },
    })

    gsap.from(
      '.extreme-section__back span, .extreme-section__title, .extreme-section__subtitle',
      {
        scrollTrigger: {
          trigger: '.extreme-section__back',
          // start: 'bottom bottom'
        },
        autoAlpha: 0,
        scale: 1.6,
        duration: 1.2,
        stagger: 0.2,
        ease: 'Expo.In',
      }
    )
  }

  const parallaxHead = () => {
    const willChangeElements = document.querySelectorAll(
      '.extreme-section__head'
    )

    ScrollTrigger.create({
      trigger: '.extreme-section__head',
      // once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        } else {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.remove('will-change')
          }
        }
      },
    })

    gsap.fromTo(
      '.extreme-section__head',
      {
        scrollTrigger: {
          trigger: '.extreme-section__head',
          scrub: true,
        },
        y: 48,
        ease: 'none',
      },
      {
        scrollTrigger: {
          trigger: '.extreme-section__head',
          scrub: true,
        },
        y: -64,
        ease: 'none',
      }
    )
  }

  const animateInfo = () => {
    const willChangeElements = document.querySelectorAll(
      '.extreme-section__info'
    )

    ScrollTrigger.create({
      trigger: '.extreme-section__info',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        }
      },
    })

    gsap.from('.extreme-section__info', {
      scrollTrigger: {
        trigger: '.extreme-section__info',
        start: 'top 80%',
      },
      opacity: 0.0001,
      y: 16,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (mq.matches) return

    animateHead()
    parallaxHead()
    animateInfo()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  extremeSection.init()
  extremeSectionMobile.init()
})
