import 'jquery-validation'
import 'jquery-validation/dist/additional-methods.min'
import '../../../../static/plugins/jquery-validation/messages'

import {
	validateForm,
	ajaxSubmitForm,
	validateAndAjaxSubmitForm,
} from '../import/formSubmit'

window.app = {
	validateForm,
	ajaxSubmitForm,
	validateAndAjaxSubmitForm,
}

window.$window = $(window)
window.$document = $(document)
window.$body = $('body')
window.$header = $('.header')
window.windowWidth = $window.width()
window.windowScroll = 0
window.windowLastScroll = 0

export const base = (() => {
	// Инициализация
	let init = () => {
		window.body = document.querySelector('body')

		_setupListeners()
	}

	// Навешиванеи событий
	let _setupListeners = () => {
		// вешаем класс page_loaded после загрузки страницы
		window.addEventListener('load', () => {
			document.querySelector('body').classList.add('page_loaded')
		})
	}

	return {
		init,
	}
})()

base.init()
