const feedback = (() => {
  const scaleDown = () => {
    const elements = document.querySelectorAll('[data-feedback-head]')
    if (!elements.length) return

    const tl = gsap.timeline({
      ease: 'Expo.in',
      scrollTrigger: {
        trigger: '[data-feedback-head]',
        start: 'bottom bottom',
      },
    })

    tl.set('[data-feedback-head] > *', {
      willChange: 'transform, opacity',
    })

    tl.from('[data-feedback-head] > *', {
      opacity: 0,
      scale: 1.3,
      duration: 1.2,
      stagger: 0.2,
    })

    tl.set('[data-feedback-head] > *', {
      willChange: 'auto',
    })
  }

  // Инициализация
  let init = () => {
    const element = document.querySelectorAll('.feedback')
    if (!element.length) return

    scaleDown()

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '[data-feedback-body]',
        start: 'top 80%',
      },
    })

    tl.set('[data-feedback-body]', {
      willChange: 'opacity',
    })

    tl.from('[data-feedback-body]', {
      opacity: 0.000001,
      duration: 1.4,
      delay: 0.2,
    })

    tl.set('[data-feedback-body]', {
      willChange: 'auto',
    })

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  feedback.init()
})
