$(() => {
	$(document)
		.find('.ajax-form')
		.each((i, el) => app.validateAndAjaxSubmitForm($(el)))

	// $('form.sendFeedback').on('submit', function (e) {
	// 	let $form = $(this);
	// 	e.preventDefault();
	//
	// 	app.validateAndAjaxSubmitForm($form, function (result) {
	// 		if (result.status) {
	// 			setTimeout(function() {document.location.href = '/thank-you-page';}, 1000);
	// 		}
	// 	});
	// });

	$(document)
		.find('form.sendFeedback')
		.each((i, el) => {
			app.validateAndAjaxSubmitForm($(el), (result) => {
				if (result.status) {
					setTimeout(function () {
						document.location.href = '/thank-you-page'
					}, 1000)
				}
			})
		})
})
