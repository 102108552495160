const whom = (() => {
  // Инициализация
  let init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    const whom = document.querySelectorAll('.whom')
    if (!whom.length) return

    const changed = gsap.utils.toArray(
      '.whom__start-desktop, .whom__title .title, .whom__amount, .whom__text'
    )

    const tl = gsap.timeline({
      ease: 'Back.inOut',
      scrollTrigger: {
        trigger: '.whom__head',
        // start: 'top 80%',
        // scrub: true,
        // end: 'center center',
        // once: true,
      },
    })

    tl.set(changed, { willChange: 'transform, opacity' })
      .from(changed, {
        autoAlpha: 0,
        y: 48,
        duration: 0.6,
        stagger: 0.3,
        delay: 0.2,
      })
      .set(changed, { willChange: 'auto' })

    const whomItems = document.querySelectorAll('.whom__item')

    let changedItems = document.querySelectorAll('.whom-item')
    let changedCircles = document.querySelectorAll('.whom__circle')

    ScrollTrigger.create({
      trigger: '.whom__body',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const changedItem of changedItems) {
            changedItem.classList.add('will-change')
          }

          for (const changedCircle of changedCircles) {
            changedCircle.classList.add('will-change')
          }
        } else {
          for (const changedItem of changedItems) {
            changedItem.classList.remove('will-change')
          }

          for (const changedCircle of changedCircles) {
            changedCircle.classList.remove('will-change')
          }
        }
      },
    })

    for (let i = 0; i < whomItems.length; i++) {
      const whomItemsElement = whomItems[i]
      const whomItem = whomItemsElement.querySelector('.whom-item')
      const whomCircle = whomItemsElement.querySelector('.whom__circle')

      const tl = gsap.timeline({
        ease: 'Back.inOut',
        delay: i * 0.4,
        scrollTrigger: {
          trigger: '.whom__body',
          start: 'top 80%',
        },
      })

      tl.from(whomItem, {
        autoAlpha: 0,
        scale: 1.3,
        duration: 0.6,
      })
        .from(
          whomItem,
          {
            y: 48,
            duration: 1.4,
            ease: 'Sine.easeOut',
          },
          '-=0.4'
        )
        .from(
          whomCircle,
          {
            autoAlpha: 0,
            duration: 0.4,
            ease: 'Sine.easeOut',
          },
          '<'
        )
        .from(
          whomCircle,
          {
            rotation: -60,
            duration: 2.4,
            delay: 0.04,
            ease: 'Expo.easeInOut',
          },
          '<'
        )
    }

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {}

  return {
    init,
  }
})()

const whomMobile = (() => {
  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (mq.matches) return

    const changeHeadElements = document.querySelectorAll(
      '.whom__start-mobile, .whom__title .title, .whom__amount, .whom__text'
    )

    ScrollTrigger.create({
      trigger: '.whom__head',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const changeHeadElement of changeHeadElements) {
            changeHeadElement.classList.add('will-change')
          }
        }
      },
    })

    const changed = gsap.utils.toArray(
      '.whom__start-mobile, .whom__title .title, .whom__amount, .whom__text'
    )

    gsap.from(changed, {
      autoAlpha: 0,
      y: 16,
      duration: 0.6,
      stagger: 0.3,
      delay: 0.2,
      ease: 'Sine.easeOut',
      scrollTrigger: {
        trigger: '.whom__head',
      },
      onComplete: () => {
        for (const changeHeadElement of changeHeadElements) {
          changeHeadElement.classList.remove('will-change')
        }
      },
    })

    // Body
    const changeBodyElements = document.querySelectorAll('.whom-item')

    ScrollTrigger.create({
      trigger: '.whom__body',
      // once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const changeBodyElement of changeBodyElements) {
            changeBodyElement.classList.add('will-change')
          }
        } else {
          for (const changeBodyElement of changeBodyElements) {
            changeBodyElement.classList.remove('will-change')
          }
        }
      },
    })

    const whomItems = document.querySelectorAll('.whom__item')

    for (let i = 0; i < whomItems.length; i++) {
      const whomItemsElement = whomItems[i]
      const whomItem = whomItemsElement.querySelector('.whom-item')

      const tl = gsap.timeline({
        ease: 'Sine.easeOut',
        scrollTrigger: {
          trigger: whomItemsElement,
          start: 'top 80%',
          toggleActions: 'play pause resume reverse',
        },
      })

      tl.from(whomItem, {
        opacity: 0.0001,
        scale: 1.3,
        delay: 0.1,
        // duration: 0.6,
      }).from(
        whomItem,
        {
          y: 24,
          delay: 0.3,
          // duration: 1.4,
        },
        '<'
      )
    }

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  whom.init()
  whomMobile.init()
})
