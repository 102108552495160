import ScrollTrigger from 'gsap/ScrollTrigger'

const introSection = (() => {
	const animation = () => {
		const mq1024 = window.matchMedia('(min-width: 1024px)')
		const canvas = document.getElementById('sequence-intro')
		const context = canvas.getContext('2d')

		canvas.width = 1067
		canvas.height = 600

		const frameCount = 96
		const currentFrame = (index) =>
			`/theme/assets/images/sequences/sequence-intro/sequence_${(
				index + 1
			)
				.toString()
				.padStart(5, '0')}.jpg`

		const images = []
		const animate = {
			frame: 0,
		}

		for (let i = 0; i < frameCount; i++) {
			const img = new Image()
			img.src = currentFrame(i)
			images.push(img)
		}

		let tl

		if (mq1024.matches) {
			tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.intro-section',
					start: 'top top',
					// end: 'bottom bottom',
					scrub: true,
				},
			})
		} else {
			tl = gsap.timeline({
				scrollTrigger: {
					trigger: '.intro-section',
					start: 'top top',
					end: 'bottom center',
					scrub: true,
				},
			})
		}

		tl.to(animate, {
			// ease: 'none',
			frame: frameCount - 1,
			snap: 'frame',
			onStart: () => {
				ScrollTrigger.refresh()
			},
			onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
		})

		images[0].onload = render

		function render() {
			context.clearRect(0, 0, canvas.width, canvas.height)
			context.drawImage(images[animate.frame], 0, 0)
		}

		let tlContent

		if (mq1024.matches) {
			gsap.to('.intro-section__head', {
				scrollTrigger: {
					trigger: '.intro-section__head',
					start: 'top top',
					scrub: true,
				},
				scale: 0.92,
				autoAlpha: 0.001,
			})

			tlContent = gsap.timeline({
				scrollTrigger: {
					trigger: '.intro-section__content',
					pin: true,
					end: '+=2000',
					scrub: true,
					anticipatePin: 1,
				},
				ease: 'none',
			})

			tlContent.set(
				'.intro-section__text-description, .intro-section__canvas-wrap, .intro-section__light, .intro-section__circle-wrap',
				{
					willChange: 'transform, opacity',
				}
			)

			tlContent
				.from(
					'.intro-section__text-description',
					{
						autoAlpha: 0.001,
						y: 8,
					},
					'-=0.3'
				)
				.to('.intro-section__text-description', {
					autoAlpha: 0.001,
					scale: 0.8,
					// delay: 1,
				})
				.to(
					'.intro-section__canvas-wrap, .intro-section__light, .intro-section__circle-wrap',
					{
						autoAlpha: 0.001,
						scale: 0.9,
						stagger: 0.1,
						ease: 'Power1.easeInOut',
					},
					'-=0.4'
				)

			tlContent.set(
				'.intro-section__text-description, .intro-section__canvas-wrap, .intro-section__light, .intro-section__circle-wrap',
				{
					willChange: 'auto',
				}
			)
		}
	}

	const start = () => {
		const mq1024 = window.matchMedia('(min-width: 1024px)')
		if (!mq1024.matches) return

		const tlStart = gsap.timeline()

		tlStart.set(
			'.intro-section__light-wrap, .intro-section__canvas, .intro-section__circle',
			{
				willChange: 'transform, opacity',
			}
		)

		tlStart
			.to('.intro-section__light-wrap', {
				opacity: 1,
				duration: 1.2,
				ease: 'Expo.easeInOut',
				delay: 2.8,
			})
			.to(
				'.intro-section__canvas',
				{
					opacity: 1,
					y: 0,
					ease: 'Sine.easeOut',
				},
				'-=0.4'
			)
			.to(
				'.intro-section__circle',
				{
					rotate: 0,
					opacity: 1,
					duration: 0.4,
					ease: 'Sine.easeOut',
				},
				'-=0.8'
			)

		tlStart.set(
			'.intro-section__light-wrap, .intro-section__canvas, .intro-section__circle',
			{
				willChange: 'auto',
			}
		)
	}

	const init = () => {
		const introSection = document.querySelectorAll('.intro-section')
		if (!introSection.length) return

		animation()

		_setupListeners()
	}

	const _setupListeners = () => {}

	return {
		init,
		start,
	}
})()

introSection.init()

window.addEventListener('load', () => {
	introSection.start()
})
