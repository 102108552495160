app.mapsInit = (apiKey, initFn) => {
    let check_if_load = false;

    function loadScript(url, callback) {
        var script = document.createElement("script");

        if (script.readyState) {  // IE
            script.onreadystatechange = function () {
                if (script.readyState === "loaded" || script.readyState === "complete") {
                    script.onreadystatechange = null;
                    callback();
                }
            };
        } else {  // Другие браузеры
            script.onload = function () {
                callback();
            };
        }

        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
    }

    var ymapInit = function () {
        if (!check_if_load) { // проверяем первый ли раз загружается Яндекс.Карта, если да, то загружаем

            // Чтобы не было повторной загрузки карты, мы изменяем значение переменной
            check_if_load = true;

            // Показываем индикатор загрузки до тех пор, пока карта не загрузится
            // spinner.addClass('is-active');

            let mapsLink = `https://api-maps.yandex.ru/2.1/?apikey=${apiKey}&lang=ru_RU`

            // Загружаем API Яндекс.Карт
            loadScript(mapsLink, function () {
                // Как только API Яндекс.Карт загрузились,
                // сразу формируем карту и помещаем в блок с идентификатором &#34;map-yandex&#34;
                ymaps.load(initFn);
            });
        }
    }

    ymapInit();
};
