const screenAbout = (() => {
	const init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (!mq.matches) return

		let pictureTl = gsap.timeline({
			scrollTrigger: {
				trigger: '.screen-about',
				pin: true,
				scrub: true,
				start: 'top top',
				end: 'bottom bottom',
				// end: '+=1000',
				preventOverlaps: true,
				fastScrollEnd: true,
				anticipatePin: 1,
			},
			ease: 'Expo.easeOut',
		})

		pictureTl
			.from('.screen-about__picture', {
				autoAlpha: 0.001,
				scale: 1.2,
				ease: 'none',
			})
			.to(
				'.screen-about__overlay',
				{
					autoAlpha: 0.001,
					scale: 10,
					ease: 'none',
				},
				'-=0.5'
			)

		_setupListeners()
	}

	const _setupListeners = () => {}

	return {
		init,
	}
})()

screenAbout.init()

const screenAboutMobile = (() => {
	const init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (mq.matches) return

		const changeElements = document.querySelectorAll(
			'.screen-about__picture'
		)

		ScrollTrigger.create({
			trigger: '.screen-about__picture',
			once: true,
			onToggle: (self) => {
				if (self.isActive) {
					for (const changeElement of changeElements) {
						changeElement.classList.add('will-change')
					}
				} else {
					for (const changeElement of changeElements) {
						changeElement.classList.remove('will-change')
					}
				}
			},
		})

		gsap.from('.screen-about__picture', {
			scrollTrigger: {
				trigger: '.screen-about__picture',
			},
			autoAlpha: 0,
			duration: 1.2,
			ease: 'Sine.easeOut',
		})

		_setupListeners()
	}

	const _setupListeners = () => {}

	return {
		init,
	}
})()

screenAboutMobile.init()
