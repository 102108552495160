const menu = (() => {
  // const mq = window.matchMedia('(min-width: 1024px)')

  // Селектор
  const menu = document.querySelector('.menu')

  // DATA
  let data = {
    menuLinkAll: document.querySelectorAll('[data-anchor-link]'),
    buttonMenuOpen: document.querySelector('[data-menu-open]'),
    buttonMenuClose: document.querySelector('[data-menu-close]'),
  }

  // METHODS
  let methods = {
    openMenu: () => {
      body.classList.add('menu_open')

      gsap.from('[data-menu-item]', {
        yPercent: -50,
        opacity: 0,
        duration: 0.3,
        stagger: 0.1,
        force3D: true,
        delay: 0.5,
      })

      gsap.from('[data-menu-info]', {
        duration: 0.6,
        opacity: 0,
        delay: 0.9,
        // xPercent: 10,
      })

      gsap.from('[data-menu-actions]', {
        duration: 0.6,
        opacity: 0,
        delay: 1,
        // xPercent: 10,
      })
    },
    closeMenu: () => {
      body.classList.add('menu_closing')

      gsap.to('[data-menu-body]', {
        duration: 0.5,
        delay: 0.4,
        x: -64,
        opacity: 0,
      })

      gsap.set('[data-menu-body]', {
        delay: 1.2,
        x: 0,
        opacity: 1,
      })

      setTimeout(() => {
        body.classList.remove('menu_closing', 'menu_open')
      }, 900)
    },
    scrollToTarget: (element) => {
      if (window.bodyScrollBar) {
        bodyScrollBar.update()

        setTimeout(() => {
          bodyScrollBar.scrollIntoView(element)
        }, 100)
      } else {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    },
  }

  // Инициализация
  let init = () => {
    if (!menu) return

    window.closeMenu = methods.closeMenu

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {
    data.buttonMenuOpen.addEventListener('click', methods.openMenu)
    data.buttonMenuClose.addEventListener('click', methods.closeMenu)

    menu.addEventListener('click', (event) => {
      if (event.target.classList.contains('menu__backdrop')) {
        methods.closeMenu()
      }
    })

    for (let index = 0; index < data.menuLinkAll.length; index++) {
      const link = data.menuLinkAll[index]
      const anchorLink = link.dataset.anchorLink
      const anchorTarget = document.querySelector(
        '[data-anchor-target="' + anchorLink + '"]'
      )

      link.addEventListener('click', (event) => {
        event.preventDefault()

        if (window.bodyScrollBar) {
          methods.scrollToTarget(anchorTarget)
          setTimeout(() => {
            methods.closeMenu()
          }, 200)
        } else {
          methods.closeMenu()
          setTimeout(() => {
            methods.scrollToTarget(anchorTarget)
          }, 200)
        }
      })
    }
  }

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  menu.init()
})
