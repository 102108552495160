import anime from 'animejs'

const dischargeSection = (() => {
  const animate = () => {
    gsap.from('.discharge-section__arrow, .discharge-section__title', {
      scrollTrigger: {
        trigger: '.discharge-section__head',
        start: 'center bottom',
      },
      autoAlpha: 0,
      scale: 1.2,
      duration: 1.8,
    })

    gsap.from('.discharge-section__animate', {
      scrollTrigger: {
        trigger: '.discharge-section__animate',
        start: 'center bottom',
      },
      autoAlpha: 0,
      duration: 1.8,
    })

    gsap.from('.discharge-section__image', {
      scrollTrigger: {
        trigger: '.discharge-section__image',
        start: 'top 90%',
        end: 'center bottom',
        scrub: 2,
      },
      y: -96,
      autoAlpha: 0,
      duration: 2,
      ease: 'Circ.easeOut',
    })
  }

  let animatePath = () => {
    const tl = anime.timeline({
      easing: 'linear',
    })

    tl.add({
      targets: '.discharge-section__left-tube',
      strokeDashoffset: [anime.setDashoffset, 0],
    })
      .add(
        {
          targets: '.discharge-section__right-tube',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 800,
        },
        100
      )
      .add(
        {
          targets: '.discharge-section__center-tube',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 750,
        },
        150
      )
      .add({
        targets: '.discharge-section__out-tube',
        strokeDashoffset: [anime.setDashoffset, 0],
      })
      .add(
        {
          targets: '.discharge-section__left-liquid',
          strokeDashoffset: [anime.setDashoffset, 0],
        },
        '-=1000'
      )
      .add(
        {
          targets: '.discharge-section__right-liquid',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 700,
        },
        '-=800'
      )
      .add(
        {
          targets: '.discharge-section__center-liquid',
          strokeDashoffset: [anime.setDashoffset, 0],
          duration: 650,
        },
        '-=750'
      )
      .add({
        targets: '.discharge-section__out-liquid',
        strokeDashoffset: [anime.setDashoffset, 0],
      })

    tl.pause()
    tl.seek(0)

    ScrollTrigger.create({
      trigger: '.discharge-section__body',
      start: 'top center',
      end: 'bottom center',
      scrub: 1,
      onUpdate: (self) => {
        tl.seek(tl.duration * self.progress.toFixed(3))
      },
    })
  }

  const init = () => {
    const element = document.querySelectorAll('.discharge-section')
    if (!element.length) return

    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    animate()
    animatePath()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

const dischargeSectionMobile = (() => {
  const animateHead = () => {
    const willChangeElements = document.querySelectorAll(
      '.discharge-section__arrow, .discharge-section__title'
    )

    ScrollTrigger.create({
      trigger: '.discharge-section__head',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        }
      },
    })

    gsap.from('.discharge-section__arrow, .discharge-section__title', {
      scrollTrigger: {
        trigger: '.discharge-section__head',
        start: 'top 80%',
      },
      autoAlpha: 0,
      scale: 1.2,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })
  }

  const animateBody = () => {
    const willChangeElements = document.querySelectorAll(
      '.discharge-section__body'
    )

    ScrollTrigger.create({
      trigger: '.discharge-section__body',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        }
      },
    })

    gsap.from('.discharge-section__body', {
      scrollTrigger: {
        trigger: '.discharge-section__body',
        start: 'top 80%',
      },
      opacity: 0.0001,
      y: 16,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (mq.matches) return

    animateHead()
    animateBody()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  dischargeSection.init()
  dischargeSectionMobile.init()
})
