const dealerMenu = (() => {
  // const mq = window.matchMedia('(min-width: 1024px)')

  // Селектор
  const dealerMenu = document.querySelector('.dealer-menu')

  // DATA
  let data = {
    buttonMenuOpenAll: document.querySelectorAll('[data-dealer-open]'),
    buttonMenuClose: document.querySelector('[data-dealer-close]'),
    buttonNext: document.querySelector('[data-dealer-next]'),
    step1: document.querySelector('[data-dealer-step-1]'),
    step2: document.querySelector('[data-dealer-step-2]'),
  }

  // // METHODS
  let methods = {
    openMenu: () => {
      if (body.classList.contains('menu_open')) {
        window.closeMenu()
        setTimeout(() => {
          body.classList.add('dealer_open')
        }, 1000)
      } else {
        body.classList.add('dealer_open')
      }
    },
    closeMenu: () => {
      body.classList.add('dealer_closing')

      setTimeout(() => {
        body.classList.remove('dealer_closing', 'dealer_open')
      }, 1000)
    },
  }

  // Инициализация
  let init = () => {
    if (!dealerMenu) return

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {
    // data.buttonMenuOpenAll.addEventListener('click', methods.openMenu)

    for (let index = 0; index < data.buttonMenuOpenAll.length; index++) {
      const element = data.buttonMenuOpenAll[index]
      element.addEventListener('click', methods.openMenu)
    }

    data.buttonMenuClose.addEventListener('click', methods.closeMenu)

    dealerMenu.addEventListener('click', (event) => {
      if (event.target.classList.contains('dealer-menu__backdrop')) {
        methods.closeMenu()
      }
    })

    data.buttonNext.addEventListener('click', () => {
      data.step1.classList.add('_is-hidden')
      data.step2.classList.remove('_is-hidden')
    })
  }

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  dealerMenu.init()
})
