//Валидация формы
export const validateForm = ($formObject, callbackSuccess) => {
	if (!$formObject) return

	let ignore = '.no-validate'

	if ($formObject.data('validateHidden')) {
		ignore += ', :not(:visible)'
	}

	$formObject.validate({
		lang: $('html').attr('lang'),
		errorClass: '_error_field',
		ignore: ignore,
		errorPlacement: (error, element) => {
			let $errPlacement = element.closest('[data-error-reserved]')
			error.appendTo(
				$errPlacement.length ? $errPlacement : element.parent()
			)
		},
		submitHandler: (form, event) => {
			if ($(form).hasClass('feedback')) {
				$('.feedback__action .button.--act').hide()
				$('.feedback__action .button.--sent').show()
			}

			if (typeof callbackSuccess === 'function') {
				event.preventDefault()
				callbackSuccess($(form))
			} else {
				form.submit()
			}
		},
		highlight: (element, errorClass, validClass) => {
			$(element).addClass(errorClass).removeClass(validClass)

			$(element).closest('.feedback__form-field').addClass('_error')
			$(element)
				.closest(
					'.select-form, .feedback__form-select, .calculator .select'
				)
				.find('.select2-selection')
				.addClass('_error')
		},
		unhighlight: function (element, errorClass, validClass) {
			$(element).removeClass(errorClass).addClass(validClass)

			$(element).closest('.feedback__form-field').removeClass('_error')
		},
	})
}

//Ajax-отправка формы на сервер
export const ajaxSubmitForm = ($form, callbackSuccess, callbackBeforeSend) => {
	if ($form.attr('enctype') === 'multipart/form-data') {
		$.ajax({
			url: $form.attr('action'),
			dataType: 'JSON',
			type: $form.attr('method'),
			data: new FormData($form[0]),
			cache: false,
			contentType: false,
			processData: false,
			beforeSend: callbackBeforeSend,
			success: callbackSuccess,
		})
	} else {
		$.ajax({
			url: $form.attr('action'),
			type: $form.attr('method'),
			dataType: 'JSON',
			data: $form.serialize(),
			beforeSend: callbackBeforeSend,
			success: callbackSuccess,
		})
	}
}

//Валидация и Ajax-отправка формы на сервер
export const validateAndAjaxSubmitForm = (
	$formObject,
	callbackSuccess,
	callbackBeforeSend,
	callbackError
) => {
	if (!$formObject) return

	app.validateForm($formObject, function ($form) {
		if (
			$form.find('._loading[type="submit"], ._disabled[type="submit"]')
				.length
		)
			return

		let $forMessages = $form.find('.forMessages')

		app.ajaxSubmitForm(
			$form,
			function (result) {
				$form.find('._disabled[type="submit"]').removeClass('_disabled')

				if (result.status) {
					if (typeof callbackSuccess === 'function') {
						callbackSuccess(result, $form)
					} else {
						if (result.link) {
							document.location.href = result.link
						} else if (result.message) {
							app.alertPopup(result.message)
							$form[0].reset()
						}
					}
					return
				}

				if ($form.find('.g-recaptcha').length) grecaptcha.reset()

				if (typeof callbackError === 'function') {
					callbackError(result)
					return
				}

				if (!$forMessages.length) {
					app.alertPopup(result.message)
					return
				}

				if (
					result.placement &&
					$formObject.find(result.placement).length
				) {
					$forMessages = $formObject.find(result.placement)
				}

				$forMessages.html(result.message)

				$forMessages.each(function (i, el) {
					let $modalContent = $('.modal-content')

					setTimeout(function () {
						$('.surface').animate(
							{ scrollTop: $(el).offset().top },
							300
						)
						$modalContent.animate(
							{
								scrollTop:
									$(el).offset().top + $modalContent.height,
							},
							300
						)
					}, 0)
				})
			},
			function () {
				$form.find('[type="submit"]').addClass('_disabled')
				$forMessages.empty()
				if (typeof callbackBeforeSend === 'function')
					callbackBeforeSend()
			}
		)
	})
}
