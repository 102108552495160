import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'

import Scrollbar from 'smooth-scrollbar'
import SoftScroll from './soft-scroll'
import LockScroll from './lock-scroll'

gsap.registerPlugin(ScrollTrigger, CSSRulePlugin)
Scrollbar.use(SoftScroll, LockScroll)

const scroll = (() => {
	window.ScrollTrigger = ScrollTrigger

	// Инициализация
	let init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (!mq.matches) return

		window.CSSRulePlugin = CSSRulePlugin

		const scroller = document.querySelector('.layout')

		window.bodyScrollBar = Scrollbar.init(scroller, {
			continuousScrolling: false,
		})

		window.ScrollTrigger.scrollerProxy('.layout', {
			scrollTop(value) {
				if (arguments.length) {
					window.bodyScrollBar.scrollTop = value
				}
				return window.bodyScrollBar.scrollTop
			},
			getBoundingClientRect() {
				return {
					top: 0,
					left: 0,
					width: window.innerWidth,
					height: window.innerHeight,
				}
			},
		})

		window.ScrollTrigger.defaults({
			scroller: scroller,
		})

		_setupListeners()
	}

	// Навешиванеи событий
	let _setupListeners = () => {
		window.bodyScrollBar.addListener(ScrollTrigger.update)
	}

	// Доступные методы
	return {
		init,
	}
})()

scroll.init()

let togglePageScrolledClass = (windowScroll) => {
	if (windowScroll > 32) {
		document.body.classList.add('page_scrolled')
	} else {
		document.body.classList.remove('page_scrolled')
	}
}

if (window.bodyScrollBar) {
	window.bodyScrollBar.addListener(
		_throttle((event) => {
			togglePageScrolledClass(event.offset.y)
		}, 200)
	)
} else {
	window.addEventListener(
		'scroll',
		_throttle(() => {
			togglePageScrolledClass(window.scrollY)
		}, 200)
	)
}
