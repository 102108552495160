import anime from 'animejs/lib/anime.es'

const energy = (() => {
  const animateSvg = () => {
    anime({
      targets: '[data-energy-path]',
      opacity: [0, 1],
      d: [
        {
          value:
            'M262.53 57.4695C205.904 0.843492 114.096 0.843492 57.4695 57.4695C0.843494 114.096 0.843494 205.904 57.4695 262.53C114.096 319.157 205.904 319.156 262.53 262.53C319.156 205.904 319.157 114.096 262.53 57.4695Z',
        },

        {
          value:
            'M252.001 66.0005C144.001 -20.999 126.001 22.0005 57.4699 57.4698C-11.061 92.9391 9.18864 197.649 68.0011 252C140.5 319 217.001 321 252.001 255C287.001 189 360 153 252.001 66.0005Z',
        },
        {
          value:
            'M268.958 50.2179C229.958 -2.78204 76.959 26.7182 58.928 57.1871C40.8969 87.656 -18.6871 230.189 50.9583 269.717C124.959 311.718 234.458 303.717 274.958 269.717C315.458 235.718 307.958 103.218 268.958 50.2179Z',
        },
        {
          value:
            'M252.001 66.0005C144.001 -20.999 126.001 22.0005 57.4699 57.4698C-11.061 92.9391 9.18864 197.649 68.0011 252C140.5 319 217.001 321 252.001 255C287.001 189 360 153 252.001 66.0005Z',
        },

        {
          value:
            'M262.53 57.4695C205.904 0.843492 114.096 0.843492 57.4695 57.4695C0.843494 114.096 0.843494 205.904 57.4695 262.53C114.096 319.157 205.904 319.156 262.53 262.53C319.156 205.904 319.157 114.096 262.53 57.4695Z',
        },
      ],
      easing: 'easeOutInBounce',
      // easing: 'easeInOutQuad',
      duration: 1000,
      loop: true,
      direction: 'alternate',
    })

    anime({
      targets: '[data-energy-path2]',
      opacity: [0, 1],
      // rotate: '90',
      d: [
        {
          value:
            'M262.53 57.4695C205.904 0.843492 114.096 0.843492 57.4695 57.4695C0.843494 114.096 0.843494 205.904 57.4695 262.53C114.096 319.157 205.904 319.156 262.53 262.53C319.156 205.904 319.157 114.096 262.53 57.4695Z',
        },

        {
          value:
            'M252.001 66.0005C144.001 -20.999 126.001 22.0005 57.4699 57.4698C-11.061 92.9391 9.18864 197.649 68.0011 252C140.5 319 217.001 321 252.001 255C287.001 189 360 153 252.001 66.0005Z',
        },
        {
          value:
            'M268.958 50.2179C229.958 -2.78204 76.959 26.7182 58.928 57.1871C40.8969 87.656 -18.6871 230.189 50.9583 269.717C124.959 311.718 234.458 303.717 274.958 269.717C315.458 235.718 307.958 103.218 268.958 50.2179Z',
        },
        {
          value:
            'M252.001 66.0005C144.001 -20.999 126.001 22.0005 57.4699 57.4698C-11.061 92.9391 9.18864 197.649 68.0011 252C140.5 319 217.001 321 252.001 255C287.001 189 360 153 252.001 66.0005Z',
        },

        {
          value:
            'M262.53 57.4695C205.904 0.843492 114.096 0.843492 57.4695 57.4695C0.843494 114.096 0.843494 205.904 57.4695 262.53C114.096 319.157 205.904 319.156 262.53 262.53C319.156 205.904 319.157 114.096 262.53 57.4695Z',
        },
      ],
      easing: 'easeInOutBounce',
      // easing: 'easeInOutQuad',
      duration: 1000,
      loop: true,
      direction: 'alternate',
      delay: 200,
    })

    anime({
      targets: '[data-energy-path3]',
      opacity: [0, 1],
      // rotate: '180',
      d: [
        {
          value:
            'M262.53 57.4695C205.904 0.843492 114.096 0.843492 57.4695 57.4695C0.843494 114.096 0.843494 205.904 57.4695 262.53C114.096 319.157 205.904 319.156 262.53 262.53C319.156 205.904 319.157 114.096 262.53 57.4695Z',
        },

        {
          value:
            'M252.001 66.0005C144.001 -20.999 126.001 22.0005 57.4699 57.4698C-11.061 92.9391 9.18864 197.649 68.0011 252C140.5 319 217.001 321 252.001 255C287.001 189 360 153 252.001 66.0005Z',
        },
        {
          value:
            'M268.958 50.2179C229.958 -2.78204 76.959 26.7182 58.928 57.1871C40.8969 87.656 -18.6871 230.189 50.9583 269.717C124.959 311.718 234.458 303.717 274.958 269.717C315.458 235.718 307.958 103.218 268.958 50.2179Z',
        },
        {
          value:
            'M252.001 66.0005C144.001 -20.999 126.001 22.0005 57.4699 57.4698C-11.061 92.9391 9.18864 197.649 68.0011 252C140.5 319 217.001 321 252.001 255C287.001 189 360 153 252.001 66.0005Z',
        },

        {
          value:
            'M262.53 57.4695C205.904 0.843492 114.096 0.843492 57.4695 57.4695C0.843494 114.096 0.843494 205.904 57.4695 262.53C114.096 319.157 205.904 319.156 262.53 262.53C319.156 205.904 319.157 114.096 262.53 57.4695Z',
        },
      ],
      easing: 'easeInElastic(3, .6)',
      // easing: 'easeInOutQuad',
      duration: 1000,
      loop: true,
      direction: 'alternate',
      delay: 400,
    })
  }

  const animateText = () => {
    anime.set('[data-energy-text]', {
      opacity: 0,
    })

    ScrollTrigger.create({
      trigger: '[data-energy-svg]',
      start: 'bottom bottom',
      onEnter: () => {
        console.log('text energy')

        anime({
          targets: '[data-energy-text]',
          opacity: 1,
          easing: 'easeInOutBounce',
          duration: 1200,
          delay: 200,
        })
      },
      once: true,
    })
  }

  const svgShowAnimate = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '[data-energy-svg]',
        start: 'bottom bottom',
      },
    })

    // data-energy-back-svg
    tl.from('[data-energy-svg]', {
      scrollTrigger: {
        trigger: '[data-energy-svg]',
        start: 'bottom bottom',
      },
      autoAlpha: 0,
      scale: 1.2,
      duration: 2,
      ease: 'Expo.easeOut',
    })
      .from('[data-energy-back-svg]', {
        autoAlpha: 0,
        duration: 0.4,
      })
      .from('[data-energy-circle-svg]', {
        opacity: 0.1,
        scale: 1.2,
        transformOrigin: 'center center',
        duration: 0.6,
      })
      .from(
        '[data-energy-light]',
        {
          opacity: 0,
          scale: 1.1,
          transformOrigin: 'center center',
          duration: 2,
          ease: 'Elastic.easeOut',
        },
        '-=0.2'
      )
  }

  const hideEnergy = () => {
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: '[data-energy]',
        start: 'top 10%',
        end: 'center top',
        scrub: true,
      },
    })

    tl.to('[data-energy]', {
      opacity: 0.2,
      duration: 1,
    }).to(
      '[data-energy-text]',
      {
        width: '30%',
        duration: 1,
      },
      '-=0.9'
    )
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    animateSvg()
    animateText()
    svgShowAnimate()
    hideEnergy()
  }

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  energy.init()
})
