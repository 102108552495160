import sequenceAnimate from '../../assets/javascript/global/sequence-animate'

const bodySection = (() => {
	// Селектор
	const bodySection = document.querySelectorAll('.body-section')

	const shieldAnimation = () => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.body-guarantee__head',
				scrub: true,
				end: 'center center',
			},
		})

		tl.from('.body-guarantee__title', {
			autoAlpha: 0,
			ease: 'Expo.Out',
			duration: 1,
		})
			.from('.body-guarantee__svg-shield-tablet', {
				autoAlpha: 0,
				y: -32,
				scaleX: 0.7,
				scaleY: 0.5,
				ease: 'Back.inOut',
				duration: 1,
			})
			.from(
				'.body-guarantee__wing-left',
				{
					autoAlpha: 0,
					rotate: 15,
					x: -48,
					ease: 'Back.inOut',
					duration: 1,
				},
				'-=0.9'
			)
			.from(
				'.body-guarantee__wing-right',
				{
					autoAlpha: 0,
					rotate: -15,
					x: 48,
					ease: 'Back.inOut',
					duration: 1,
				},
				'-=1'
			)
			.from(
				'.body-guarantee__icon',
				{
					autoAlpha: 0,
					scale: 1.3,
					duration: 1,
					ease: 'Expo.inOut',
				},
				'-=0.9'
			)
			.from(
				'.body-guarantee__stem',
				{
					autoAlpha: 0,
					scale: 1.3,
					y: -24,
					duration: 1,
					ease: 'Expo.inOut',
				},
				'-=0.7'
			)

		gsap.from('.body-guarantee__lines, .body-guarantee__arrow', {
			scrollTrigger: {
				trigger: '.body-guarantee__arrow',
				scrub: true,
				end: 'center center',
			},
			ease: 'Back.inOut',
			autoAlpha: 0,
			y: -32,
			stagger: 0.4,
		})

		const tlItems = gsap.timeline({
			scrollTrigger: {
				trigger: '.body-guarantee__wrap-sequence',
				scrub: 0.5,
				start: 'top top',
				end: '+=6600px',
			},
		})

		tlItems
			.from('.body-guarantee__item-sequence._text-one', {
				ease: 'Expo.Out',
				autoAlpha: 0,
				y: 32,
			})
			.to('.body-guarantee__item-sequence._text-one', {
				autoAlpha: 0,
			})
			.from('.body-guarantee__item-sequence._text-two', {
				ease: 'Expo.Out',
				autoAlpha: 0,
				y: 32,
			})
			.to('.body-guarantee__item-sequence._text-two', {
				autoAlpha: 0,
			})
	}

	const animateGuarantee = () => {
		let options = {
			id: 'sequence-guarantee',
			width: 1920,
			height: 1080,
			frameCount: 336,
			folder: 'sequence-guarantee',
			triggerClass: '.body-guarantee__sequence',
			end: '+=6600px',
		}

		sequenceAnimate.init(options)

		gsap.to('.body-guarantee__sequence', {
			scrollTrigger: {
				trigger: '.body-guarantee .pin-spacer',
				start: '90% center',
				end: 'bottom bottom',
				scrub: true,
			},
			autoAlpha: 0,
		})
	}

	// Инициализация
	let init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (!mq.matches) return

		if (bodySection.length === 0) return

		let options = {
			id: 'sequence-body',
			width: 1067,
			height: 600,
			frameCount: 97,
			folder: 'sequence-body',
			triggerClass: '.body-section__sequence',
			end: '+=1600px',
		}

		sequenceAnimate.init(options)

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.body-section__wrap-sequence',
				scrub: true,
				start: 'top top',
				end: '+=1616px',
			},
		})

		tl.from('.body-section__item-sequence', {
			autoAlpha: 0,
			y: 32,
			duration: 0.5,
			stagger: 0.5,
		}).to(
			'.body-section__item-sequence',
			{
				autoAlpha: 0,
				duration: 0.5,
				stagger: 0.5,
			},
			'-=1'
		)

		gsap.fromTo(
			'.body-section__head',
			{
				scrollTrigger: {
					trigger: '.body-section__head',
					scrub: true,
				},
				y: 160,
				ease: 'none',
			},
			{
				scrollTrigger: {
					trigger: '.body-section__head',
					scrub: true,
				},
				y: -160,
				ease: 'none',
			}
		)

		shieldAnimation()
		animateGuarantee()
		_setupListeners()
	}

	// Навешиванеи событий
	let _setupListeners = () => {}

	return {
		init,
	}
})()

const bodySectionMobile = (() => {
	const animateHead = () => {
		const willChangeElements = document.querySelectorAll(
			'.body-section__head'
		)

		ScrollTrigger.create({
			trigger: '.body-section__head',
			// once: true,
			onToggle: (self) => {
				if (self.isActive) {
					for (const willChangeElement of willChangeElements) {
						willChangeElement.classList.add('will-change')
					}
				} else {
					for (const willChangeElement of willChangeElements) {
						willChangeElement.classList.remove('will-change')
					}
				}
			},
		})

		gsap.fromTo(
			'.body-section__head',
			{
				scrollTrigger: {
					trigger: '.body-section__head',
					scrub: true,
				},
				y: 48,
				ease: 'none',
			},
			{
				scrollTrigger: {
					trigger: '.body-section__head',
					scrub: true,
				},
				y: -64,
				ease: 'none',
			}
		)
	}

	const animateShield = () => {
		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: '.body-guarantee__head',
				scrub: 2,
				end: 'center center',
			},
		})

		tl.from('.body-guarantee__title', {
			autoAlpha: 0,
			ease: 'Expo.Out',
			duration: 1,
		})
			.from('.body-guarantee__svg-shield-tablet', {
				autoAlpha: 0,
				y: -32,
				scaleX: 0.7,
				scaleY: 0.5,
				ease: 'Back.inOut',
				duration: 1,
			})
			.from(
				'.body-guarantee__wing-left',
				{
					autoAlpha: 0,
					rotate: 15,
					x: -48,
					ease: 'Back.inOut',
					duration: 1,
				},
				'-=0.9'
			)
			.from(
				'.body-guarantee__wing-right',
				{
					autoAlpha: 0,
					rotate: -15,
					x: 48,
					ease: 'Back.inOut',
					duration: 1,
				},
				'-=1'
			)
			.from(
				'.body-guarantee__icon',
				{
					autoAlpha: 0,
					scale: 1.3,
					duration: 1,
					ease: 'Expo.inOut',
				},
				'-=0.9'
			)
			.from(
				'.body-guarantee__stem',
				{
					autoAlpha: 0,
					scale: 1.3,
					y: -24,
					duration: 1,
					ease: 'Expo.inOut',
				},
				'-=0.7'
			)

		gsap.from('.body-guarantee__arrow', {
			scrollTrigger: {
				trigger: '.body-guarantee__arrow',
				scrub: true,
				end: 'center center',
			},
			ease: 'Back.inOut',
			autoAlpha: 0,
			y: -32,
			stagger: 0.4,
		})
	}

	const animateItems = () => {
		const willChangeElements = document.querySelectorAll(
			'.body-section__item, .body-guarantee__item, .body-guarantee__purification'
		)

		ScrollTrigger.create({
			trigger: '.body-section',
			once: true,
			onToggle: (self) => {
				if (self.isActive) {
					for (const willChangeElement of willChangeElements) {
						willChangeElement.classList.add('will-change')
					}
				}
			},
		})

		const bodyItems = document.querySelectorAll(
			'.body-section__item, .body-guarantee__item, .body-guarantee__purification'
		)

		for (let i = 0; i < bodyItems.length; i++) {
			const bodyItemsElement = bodyItems[i]

			gsap.from(bodyItemsElement, {
				scrollTrigger: {
					trigger: bodyItemsElement,
					start: 'top 80%',
				},
				opacity: 0.0001,
				y: 16,
				ease: 'Sine.easeOut',
				onComplete: () => {
					for (const willChangeElement of willChangeElements) {
						willChangeElement.classList.remove('will-change')
					}
				},
			})
		}
	}

	const init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (mq.matches) return

		animateHead()
		animateItems()
		animateShield()

		_setupListeners()
	}

	const _setupListeners = () => {}

	return {
		init,
	}
})()

window.addEventListener('load', () => {
	bodySection.init()
	bodySectionMobile.init()
})
