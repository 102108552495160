const garbageSection = (() => {
	const garbageSection = document.querySelectorAll('.garbage-section')

	// Инициализация
	let init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (!mq.matches) return

		if (!garbageSection.length) return

		gsap.from('.garbage-section__circle', {
			scrollTrigger: {
				trigger: '.garbage-section__block-video',
				scrub: true,
				start: 'top bottom',
				end: 'bottom top',
			},
			ease: 'none',
			y: -532,
		})

		gsap.to('.garbage-section__info', {
			scrollTrigger: {
				trigger: '.garbage-section__info',
				scrub: true,
			},
			ease: 'none',
			y: -128,
		})

		gsap.from('.garbage-section__block-video', {
			scrollTrigger: '.garbage-section__block-video',
			autoAlpha: 0,
			scale: 0.8,
			duration: 0.8,
			ease: 'Back.inOut',
		})

		const tlInfo = gsap.timeline({
			ease: 'Back.inOut',
			scrollTrigger: {
				trigger: '.garbage-section__info',
				start: 'bottom bottom',
			},
		})

		tlInfo
			.from('.garbage-section__name-info, .garbage-section__desc-info', {
				autoAlpha: 0,
				y: 32,
				duration: 1.2,
				stagger: 0.2,
				delay: 0.2,
			})
			.from(
				'.garbage-section__arrow',
				{
					ease: 'Expo.inOut',
					autoAlpha: 0,
					scaleX: 0,
					duration: 0.4,
				},
				'-=0.3'
			)

		_setupListeners()
	}

	// Навешиванеи событий
	let _setupListeners = () => {}

	return {
		init,
	}
})()

const garbageSectionMobile = (() => {
	let autoplayVideo = () => {
		const videoElement = document.getElementById('garbage-video')

		if (videoElement.playing) {
		} else {
			videoElement.play()
		}
	}

	const init = () => {
		const mq = window.matchMedia('(min-width: 1024px)')
		if (mq.matches) return

		const animateHead = () => {
			const willChangeElements = document.querySelectorAll(
				'.garbage-section__head'
			)

			ScrollTrigger.create({
				trigger: '.garbage-section__head',
				once: true,
				onToggle: (self) => {
					if (self.isActive) {
						for (const willChangeElement of willChangeElements) {
							willChangeElement.classList.add('will-change')
						}
					} else {
						for (const willChangeElement of willChangeElements) {
							willChangeElement.classList.remove('will-change')
						}
					}
				},
			})

			gsap.from('.garbage-section__head', {
				scrollTrigger: {
					trigger: '.garbage-section__head',
					start: 'top 80%',
				},
				autoAlpha: 0,
				scale: 1.2,
				ease: 'Sine.easeOut',
				onComplete: () => {
					for (const willChangeElement of willChangeElements) {
						willChangeElement.classList.remove('will-change')
					}
				},
			})
		}

		const animateBody = () => {
			const willChangeElements = document.querySelectorAll(
				'.garbage-section__body'
			)

			ScrollTrigger.create({
				trigger: '.garbage-section__body',
				once: true,
				onToggle: (self) => {
					if (self.isActive) {
						for (const willChangeElement of willChangeElements) {
							willChangeElement.classList.add('will-change')
						}
					} else {
						for (const willChangeElement of willChangeElements) {
							willChangeElement.classList.remove('will-change')
						}
					}
				},
			})

			gsap.from('.garbage-section__body', {
				scrollTrigger: {
					trigger: '.garbage-section__body',
					start: 'top 80%',
				},
				opacity: 0.0001,
				ease: 'Sine.easeOut',
				onComplete: () => {
					for (const willChangeElement of willChangeElements) {
						willChangeElement.classList.remove('will-change')
					}
				},
			})
		}

		animateHead()
		animateBody()

		_setupListeners()
	}

	const _setupListeners = () => {
		document.addEventListener('click', autoplayVideo)
		document.addEventListener('touchstart', autoplayVideo)
	}

	return {
		init,
	}
})()

window.addEventListener('load', () => {
	garbageSection.init()
	garbageSectionMobile.init()
})
