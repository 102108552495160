import List from 'list.js'
import Scrollbar from 'smooth-scrollbar'

const map = (() => {
	let $mapContainer = $('.dealer-map')

	let dealerList
	let points = []
	let mapInstance
	let clusterTemplate
	let clusterer
	let amountPoints
	let activePointCoords

	let mapReInit = (centerPointCoords) => {
		clusterer.removeAll()

		$.each(points, (i, coords) => {
			let placemarkTemplate =
				activePointCoords !== coords
					? '<div class="placemarkIcon"></div>'
					: '<div class="placemarkIcon _is-active"></div>'
			let placemark = new ymaps.Placemark(
				coords.split(','),
				{},
				{
					hasHint: false,
					iconLayout:
						ymaps.templateLayoutFactory.createClass(
							placemarkTemplate
						),
					iconShape: {
						type: 'Rectangle',
						coordinates: [
							[-23, -23],
							[23, 23],
						],
					},
				}
			)

			placemark.events.add('click', function () {
				handlePlacemarkClick(coords)
			})

			clusterer.add(placemark)
		})

		mapInstance.geoObjects.add(clusterer)
		// mapInstance.setBounds(clusterer.getBounds(), {
		// 	checkZoomRange: true,
		// })

		if (centerPointCoords) {
			setTimeout(function () {
				mapInstance.setCenter(centerPointCoords.split(','), 14)
			}, 200)

			return
		}

		if (points.length === 1) {
			setTimeout(function () {
				mapInstance.setZoom(mapInstance.getZoom() - 10)
			}, 200)
		}
	}

	let dealerSearch = () => {
		let options = {
			valueNames: [{ data: ['id', 'coords'] }, 'dealer-list__name-item'],
			searchClass: 'dealer-search__input',
			listClass: 'dealer-list__block',
			searchDelay: 250,
		}

		dealerList = new List('dealer-list', options)

		dealerList.on('searchComplete', () => {
			points = []

			for (const item of dealerList.visibleItems) {
				points.push(item._values.coords)
			}

			mapReInit()
		})
	}

	let showOnMap = () => {
		const footerLinkMap = document.querySelector('.footer__link-map')

		if (!footerLinkMap) return

		footerLinkMap.addEventListener('click', (event) => {
			event.preventDefault()

			const that = event.target
			const scrollTarget = document.querySelector('.dealer-section')

			if (window.bodyScrollBar) {
				bodyScrollBar.update()

				setTimeout(() => {
					bodyScrollBar.scrollIntoView(scrollTarget)
				}, 100)
			} else {
				scrollTarget.scrollIntoView({ behavior: 'smooth' })
			}

			if (!that.dataset.coords) return
			if (clusterer.getGeoObjects().length > amountPoints) return

			let coords = that.dataset.coords

			let placemarkTemplate =
				'<div class="placemarkIcon _is-general"></div>'
			let placemark = new ymaps.Placemark(
				coords.split(','),
				{},
				{
					hasHint: false,
					iconLayout:
						ymaps.templateLayoutFactory.createClass(
							placemarkTemplate
						),
					iconShape: {
						type: 'Rectangle',
						coordinates: [
							[-23, -23],
							[23, 23],
						],
					},
				}
			)

			clusterer.add(placemark)

			mapInstance.geoObjects.add(clusterer)

			setTimeout(function () {
				mapInstance.setCenter(coords.split(','), 14)
			}, 300)
		})
	}

	let handleDealerActive = (event) => {
		const that = event.target.closest('.dealer-list__item')
		if (!that) return

		activePointCoords = that.dataset.coords
		mapReInit(activePointCoords)

		let itemsAll = that
			.closest('.dealer-list__block')
			.querySelectorAll('.dealer-list__item')

		for (const itemsAllElement of itemsAll) {
			itemsAllElement.classList.remove('_is-active')
		}

		that.classList.add('_is-active')
	}

	let handlePlacemarkClick = (coords) => {
		activePointCoords = coords

		let eventClick = new Event('click', { bubbles: true })
		let item = document.querySelector(
			'.dealer-list__item[data-coords="' + activePointCoords + '"]'
		)

		item.dispatchEvent(eventClick)

		// scrolling item into view
		const scrollbar = Scrollbar.get(
			document.querySelector('.dealer-list__body')
		)

		if (scrollbar) {
			scrollbar.update()

			setTimeout(() => {
				scrollbar.scrollIntoView(item)
			}, 100)
		} else {
			item.scrollIntoView({ behavior: 'smooth' })
		}
	}

	let init = () => {
		if (!$mapContainer.length) return false

		app.mapsInit($('body').data('ymaps-api-key'), () => {
			mapInstance = new ymaps.Map($mapContainer[0], {
				center: [55.76, 37.64],
				zoom: 10,
				controls: ['zoomControl'],
			})

			clusterTemplate =
				'<div class="clusterIcon"><span>{{ properties.geoObjects.length }}</span></div>'
			clusterer = new ymaps.Clusterer({
				clusterIconLayout:
					ymaps.templateLayoutFactory.createClass(clusterTemplate),
				clusterIconShape: {
					type: 'Rectangle',
					coordinates: [
						[-20, -20],
						[20, 20],
					],
				},
			})

			mapInstance.behaviors.disable('scrollZoom')

			points = $mapContainer.data('points').split(';')

			$.each(points, (i, coords) => {
				let placemarkTemplate = '<div class="placemarkIcon"></div>'
				let placemark = new ymaps.Placemark(
					coords.split(','),
					{},
					{
						hasHint: false,
						iconLayout:
							ymaps.templateLayoutFactory.createClass(
								placemarkTemplate
							),
						iconShape: {
							type: 'Rectangle',
							coordinates: [
								[-23, -23],
								[23, 23],
							],
						},
					}
				)

				placemark.events.add('click', function () {
					handlePlacemarkClick(coords)
				})

				clusterer.add(placemark)
			})

			amountPoints = clusterer.getGeoObjects().length

			mapInstance.geoObjects.add(clusterer)
			mapInstance.setBounds(clusterer.getBounds(), {
				checkZoomRange: true,
			})

			if (points.length === 1) {
				setTimeout(function () {
					mapInstance.setZoom(mapInstance.getZoom() - 10)
				}, 200)
			}
		})

		dealerSearch()
		showOnMap()

		_setupListeners()
	}

	const _setupListeners = () => {
		document.addEventListener('click', handleDealerActive)

		let dealerItems = document.querySelectorAll('.dealer-list__item')

		for (const dealerItem of dealerItems) {
			dealerItem.addEventListener('mouseenter', handleDealerActive)
		}
	}

	return {
		init,
	}
})()

window.addEventListener('load', () => {
	map.init()
})
