const sizeSection = (() => {
  const init = () => {
    const sizeSection = document.querySelectorAll('.size-section')
    if (!sizeSection.length) return

    const mq = window.matchMedia('(min-width: 1024px)')
    let tlSizeSection

    if (mq.matches) {
      tlSizeSection = gsap.timeline({
        scrollTrigger: {
          trigger: '.size-section__columns',
          start: 'top center',
          end: 'bottom bottom',
          scrub: 2,
        },
        ease: 'Sine.easeOut',
      })
    } else {
      tlSizeSection = gsap.timeline({
        scrollTrigger: {
          trigger: '.size-section__columns',
          start: 'top 80%',
          toggleActions: 'play pause resume reverse',
        },
        ease: 'Sine.easeOut',
      })
    }

    const elements = document.querySelectorAll(
      '.size-section__image-graph,' +
        '.size-section__picture-graph,' +
        '.size-section__block-head,' +
        '.size-section__title, ' +
        '.size-section__subtitle'
    )

    ScrollTrigger.create({
      trigger: '.size-section__columns',
      onToggle: (self) => {
        if (self.isActive) {
          for (const element of elements) {
            element.classList.add('will-change')
          }
        } else {
          for (const element of elements) {
            element.classList.remove('will-change')
          }
        }
      },
    })

    gsap.set('.size-section__picture-graph', {
      transformOrigin: 'top left',
    })

    tlSizeSection
      .from('.size-section__picture-graph', {
        autoAlpha: 0,
        scale: 0.9,
        ease: 'Circ.easeOut',
      })
      .from(
        '.size-section__image-graph',
        {
          x: -64,
          y: -64,
        },
        '<'
      )
      .from(
        '.size-section__title',
        {
          autoAlpha: 0,
          x: -32,
          delay: 0.2,
          ease: 'Expo.easeOut',
        },
        '<'
      )
      .from(
        '.size-section__subtitle',
        {
          autoAlpha: 0,
          x: 32,
          delay: 0.3,
          ease: 'Expo.easeOut',
        },
        '<'
      )

    if (mq.matches) {
      gsap.to('.size-section__block-head', {
        scrollTrigger: {
          trigger: '.size-section__block-head',
          scrub: 1,
        },
        y: -128,
      })
    }

    // Reveal characteristics
    const characteristicsInfo = document.querySelector('.characteristics')

    ScrollTrigger.create({
      trigger: characteristicsInfo,
      onToggle: (self) => {
        if (self.isActive) {
          characteristicsInfo.classList.add('will-change')
        } else {
          characteristicsInfo.classList.remove('will-change')
        }
      },
      once: true,
    })

    gsap.from(characteristicsInfo, {
      scrollTrigger: {
        trigger: characteristicsInfo,
        start: 'top 80%',
      },
      opacity: 0.0001,
      y: 16,
      duration: 1.2,
    })

    const characteristicsPrice = document.querySelector(
      '.characteristics__price'
    )

    ScrollTrigger.create({
      trigger: characteristicsPrice,
      onToggle: (self) => {
        if (self.isActive) {
          characteristicsPrice.classList.add('will-change')
        } else {
          characteristicsPrice.classList.remove('will-change')
        }
      },
      once: true,
    })

    gsap.from(characteristicsPrice, {
      scrollTrigger: {
        trigger: characteristicsPrice,
        start: 'top 80%',
      },
      opacity: 0.0001,
      y: 16,
      duration: 1.2,
    })

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', sizeSection.init)
