const header = (() => {

  let data = {}

  const init = () => {
    data.lightSectionAll = document.querySelectorAll('[data-section-light]')

    for (let index = 0; index < data.lightSectionAll.length; index++) {
      const section = data.lightSectionAll[index]

      ScrollTrigger.create({
        trigger: section,
        start: 'top +=100px',
        end: 'bottom +=100px',
        onEnter: (self) => body.classList.add('section_light'),
        onEnterBack: (self) => body.classList.add('section_light'),
        onLeave: (self) => {
          body.classList.remove('section_light')
        },
        onLeaveBack: (self) => {
          body.classList.remove('section_light')
        }
      })
    }

    // console.log('header.js init')
    _setupListeners()
  }

  const _setupListeners = () => {

    // console.log('header.js setup listeners')
  }

  return {
    init
  }
})()

window.addEventListener('load', () => {
  // header.init()

  setTimeout(() => {
    header.init()
  }, 1000)
})
