import sequenceAnimate from '../../assets/javascript/global/sequence-animate'

const serviceSection = (() => {
  // Селектор
  const serviceSection = document.querySelectorAll('.service-section')

  const serviceSequenceAnimate = () => {
    let options = {
      id: 'sequence-service',
      width: 1920,
      height: 1080,
      frameCount: 161,
      folder: 'sequence-service',
      triggerClass: '.service-section__sequence',
      end: '+=2683px',
    }

    sequenceAnimate.init(options)
  }

  const serviceHeadAnimate = () => {
    const changed = gsap.utils.toArray(
      '.service-section__title, .service-section__desc, .service-section__subtitle'
    )

    const tl = gsap.timeline({
      ease: 'Back.inOut',
      scrollTrigger: {
        trigger: '.service-section__head',
        start: 'top center',
        // scrub: true,
        // end: 'center center',
        // once: true,
      },
    })

    tl.from(changed, {
      autoAlpha: 0,
      y: 48,
      duration: 0.6,
      stagger: 0.3,
      delay: 0.2,
    }).from(
      '.service-section__svg-circle',
      {
        autoAlpha: 0,
        rotation: -45,
        duration: 0.8,
        delay: 0.2,
        ease: 'Expo.easeOut',
      },
      '<'
    )

    gsap.from('.service-section__block-head', {
      scrollTrigger: {
        trigger: '.service-section__head',
        scrub: true,
      },
      y: 192,
    })
  }

  const serviceArrowAnimate = () => {
    gsap.from('.service-section__arrow-sequence', {
      scrollTrigger: {
        trigger: '.service-section__sequence',
        start: 'bottom 90%',
        end: 'bottom 80%',
        scrub: true,
      },
      autoAlpha: 0,
      ease: 'none',
    })

    gsap.to('.service-section__arrow-sequence', {
      scrollTrigger: {
        trigger: '.service-section__sequence',
        start: 'bottom 90%',
        scrub: true,
      },
      y: 128,
      ease: 'none',
    })
  }

  // Инициализация
  let init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (!mq.matches) return

    if (!serviceSection.length) return

    serviceHeadAnimate()
    serviceSequenceAnimate()
    serviceArrowAnimate()

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {}

  return {
    init,
  }
})()

const serviceSectionMobile = (() => {
  const animateHead = () => {
    const willChangeElements = document.querySelectorAll(
      '.service-section__title, .service-section__desc, .service-section__subtitle'
    )

    ScrollTrigger.create({
      trigger: '.service-section__head',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        } else {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.remove('will-change')
          }
        }
      },
    })

    const changed = gsap.utils.toArray(
      '.service-section__title, .service-section__desc, .service-section__subtitle'
    )

    const tl = gsap.timeline({
      ease: 'Back.inOut',
      scrollTrigger: {
        trigger: '.service-section__head',
        start: 'top 80%',
        // scrub: true,
        // end: 'center center',
        // once: true,
      },
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })

    tl.from(changed, {
      autoAlpha: 0,
      y: 16,
      duration: 0.6,
      stagger: 0.3,
      delay: 0.2,
    })
  }

  const animateBody = () => {
    const willChangeElements = document.querySelectorAll(
      '.service-section__body'
    )

    ScrollTrigger.create({
      trigger: '.service-section__body',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        } else {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.remove('will-change')
          }
        }
      },
    })

    gsap.from('.service-section__body', {
      scrollTrigger: {
        trigger: '.service-section__body',
        start: 'top 80%',
      },
      opacity: 0.0001,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (mq.matches) return

    animateHead()
    animateBody()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  serviceSection.init()
  serviceSectionMobile.init()
})
