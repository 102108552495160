import Scrollbar from 'smooth-scrollbar'

const dealerSection = (() => {
  let data = {}

  let methods = {
    animateCircle: () => {
      gsap.from('[data-dealer-circle]', {
        scrollTrigger: {
          trigger: '[data-dealer-body]',
          scrub: true,
          start: 'top bottom',
          end: 'bottom top',
        },
        ease: 'none',
        y: -468,
      })
    },
    animateDealerHead: () => {
      const tl = gsap.timeline({
        ease: 'Expo.in',
        force3D: true,
        scrollTrigger: {
          trigger: '[data-dealer-head]',
          start: 'bottom bottom',
        },
      })

      gsap.set('[data-dealer-head] > *', {
        willChange: 'transform, opacity',
      })

      tl.from('[data-dealer-head] > *', {
        opacity: 0,
        scale: 1.3,
        duration: 1.2,
        stagger: 0.2,
      }).set('[data-dealer-head] > *', {
        willChange: 'auto',
      })
    },
    animateDealerBody: () => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: '[data-dealer-body]',
        },
      })

      tl.from('[data-dealer-body]', {
        opacity: 0.000001,
        duration: 1,
        delay: 0.1,
      })
    },
  }

  const init = () => {
    data.mq = window.matchMedia('(min-width: 1024px)')
    if (!data.mq.matches) return

    const element = document.querySelectorAll('.dealer-section')
    if (!element.length) return

    data.dealerScroller = document.querySelector('[data-dealer-scroll]')
    data.dealerListElement = document.querySelector('.dealer-list__block')

    data.scrollerHeight = data.dealerScroller.clientHeight
    data.listHeight = data.dealerListElement.clientHeight

    data.scrollLength = data.listHeight - data.scrollerHeight

    data.dealerScrollBar = Scrollbar.init(data.dealerScroller, {
      continuousScrolling: false,
      damping: 0.06,
    })

    methods.animateCircle()
    methods.animateDealerHead()
    methods.animateDealerBody()

    // console.log('dealer-section.js init')
    _setupListeners()
  }

  const _setupListeners = () => {
    data.dealerScrollBar.addListener((status) => {
      // console.log('scroll - ' + status.offset.y)
      // console.log('length - ' + status.limit.y)

      if (status.offset.y === status.limit.y) {
        data.dealerScroller.classList.add('_scroll_end')
      } else {
        data.dealerScroller.classList.remove('_scroll_end')
      }

      if (status.offset.y > 0) {
        data.dealerScroller.classList.add('_scroll_scrolled')
      } else {
        data.dealerScroller.classList.remove('_scroll_scrolled')
      }
    })

    // console.log('dealer-section.js setup listeners')
  }

  return {
    init,
  }
})()

const dealerSectionMobile = (() => {
  const animateHead = () => {
    const willChangeElements = document.querySelectorAll(
      '.dealer-section__title, .dealer-list__name'
    )

    ScrollTrigger.create({
      trigger: '.dealer-section__head',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        } else {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.remove('will-change')
          }
        }
      },
    })

    const tl = gsap.timeline({
      ease: 'Expo.in',
      scrollTrigger: {
        trigger: '[data-dealer-head]',
        start: 'top 80%',
      },
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })

    tl.from('.dealer-section__title, .dealer-list__name', {
      opacity: 0,
      scale: 1.3,
      duration: 1.2,
      stagger: 0.2,
    })
  }

  const animateBody = () => {
    const willChangeElements = document.querySelectorAll(
      '.dealer-list__search, .dealer-list__body, .dealer-section__map'
    )

    ScrollTrigger.create({
      trigger: '.dealer-section__body',
      once: true,
      onToggle: (self) => {
        if (self.isActive) {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.add('will-change')
          }
        } else {
          for (const willChangeElement of willChangeElements) {
            willChangeElement.classList.remove('will-change')
          }
        }
      },
    })

    gsap.from('.dealer-list__search', {
      scrollTrigger: {
        trigger: '.dealer-list__search',
        start: 'top 80%',
      },
      duration: 1.2,
      delay: 0.2,
      opacity: 0.0001,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })

    gsap.from('.dealer-list__body', {
      scrollTrigger: {
        trigger: '.dealer-list__body',
        start: 'top 80%',
      },
      duration: 1.2,
      delay: 0.2,
      opacity: 0.0001,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })

    gsap.from('.dealer-section__map', {
      scrollTrigger: {
        trigger: '.dealer-section__map',
        start: 'top 80%',
      },
      duration: 1.2,
      delay: 0.2,
      opacity: 0.0001,
      ease: 'Sine.easeOut',
      onComplete: () => {
        for (const willChangeElement of willChangeElements) {
          willChangeElement.classList.remove('will-change')
        }
      },
    })
  }

  const init = () => {
    const mq = window.matchMedia('(min-width: 1024px)')
    if (mq.matches) return

    animateHead()
    animateBody()

    _setupListeners()
  }

  const _setupListeners = () => {}

  return {
    init,
  }
})()

window.addEventListener('load', () => {
  dealerSection.init()
  dealerSectionMobile.init()
})
