import ScrollTrigger from 'gsap/ScrollTrigger'

const sequenceAnimate = (() => {
  const mq = window.matchMedia('(min-width: 1024px)')

  // Инициализация
  let init = (options) => {
    // options = {
    // 	id,
    // 	width,
    // 	height,
    // 	frameCount,
    // 	folder,
    // 	triggerClass,
    // }

    const canvas = document.getElementById(options.id)
    const context = canvas.getContext('2d')

    canvas.width = options.width
    canvas.height = options.height

    const frameCount = options.frameCount
    const currentFrame = (index) =>
      `/theme/assets/images/sequences/${options.folder}/sequence_${(index + 1)
        .toString()
        .padStart(5, '0')}.jpg`

    const images = []
    const animate = {
      frame: 0,
    }

    for (let i = 0; i < frameCount; i++) {
      const img = new Image()
      img.src = currentFrame(i)
      images.push(img)
    }

    let tl = gsap.timeline({
      scrollTrigger: {
        trigger: options.triggerClass,
        start: 'top top',
        end: options.end,
        scrub: true,
      },
    })

    tl.to(animate, {
      ease: 'none',
      frame: frameCount - 1,
      snap: 'frame',

      onStart: () => {
        ScrollTrigger.refresh()
      },
      onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
    })

    gsap.to(options.triggerClass, {
      scrollTrigger: {
        trigger: options.triggerClass,
        end: options.end,
        pin: true,
        anticipatePin: 1,
      },
    })

    images[0].onload = render

    function render() {
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.drawImage(images[animate.frame], 0, 0)
    }

    _setupListeners()
  }

  // Навешиванеи событий
  let _setupListeners = () => {}

  // Доступные методы
  return {
    init,
  }
})()

export default sequenceAnimate
