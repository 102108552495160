import 'core-js/stable'

// GLOBAL
import './global/lazy'
import './global/base'
import './global/scroll'
import './global/magnet'

// COMPONENTS
import '../../components/load-screen/load-screen'
import '../../components/app/form/form-file'
import '../../components/core/map/map';
// ---
import '../../components/header'
import '../../components/intro-section/intro-section'
import '../../components/screen-about/screen-about'
import '../../components/app/form/form-input'
import '../../components/menu/menu'
import '../../components/feedback-menu/feedback-menu'
import '../../components/dealer-menu/dealer-menu'
import '../../components/whom/whom'
import '../../components/benefits-section/benefits-section'
import '../../components/body-section/body-section'
import '../../components/garbage-section/garbage-section'
import '../../components/service-section/service-section'
import '../../components/size-section/size-section'
import '../../components/energy/energy'
import '../../components/extreme-section/extreme-section'
import '../../components/discharge-section/discharge-section'
import '../../components/dealer-section/dealer-section'

import '../../components/feedback'
import '../../components/footer'

//Backend
import '../../components/backend/ajax-forms'
import '../../components/backend/map'

console.log('Ready!!')
